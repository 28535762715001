import { mapGetters } from 'vuex'
// important notes: the graphQL params and store params should be exactly the same data structure

const getParamForFloat = (key, value) => ({
  key,
  value: value && !isNaN(parseFloat(value)) ? parseFloat(value) : null
})

const getGraphQLParamForFloat = (key, value) => {
  let retValue = {}

  if (value !== null) {
    retValue = { [key]: parseFloat(value) }
  }
  return retValue
}

// isFlag means, its only possible to have a true value or nothing, false is not possible to set
const getParamForBoolean = (key, value, isFlag = false) => {
  let retValue = null

  if (typeof value === 'boolean') {
    if (isFlag) {
      if (value) {
        retValue = '1'
      }
    } else {
      retValue = value ? '1' : '0'
    }
  } else if (typeof value === 'string') {
    if (isFlag) {
      if (value === 'true') {
        retValue = '1'
      }
    } else {
      retValue = value === 'true' ? '1' : '0'
    }
  }

  return {
    key,
    value: retValue
  }
}

// isFlag means, its only possible to have a true value or nothing, false is not possible to set
const getGraphQLParamForBoolean = (key, value, isFlag = false) => {
  let retValue = {}

  if (typeof value === 'boolean') {
    if (isFlag) {
      if (value) {
        retValue = { [key]: true }
      }
    } else {
      retValue = { [key]: value }
    }
  } else if (typeof value === 'string') {
    if (isFlag) {
      if (value === '1') {
        retValue = { [key]: true }
      }
    } else {
      retValue = { [key]: value === '1' }
    }
  }

  return retValue
}

const getParamForIntRange = (key, value) => ({
  key,
  value:
    (value && value.min !== null) || (value && value.max !== null)
      ? [value.min, value.max].join(',')
      : null
})

const getGraphQLParamForIntRangeByStore = (
  key,
  value,
  target,
  defaultMinValue = 0
) => {
  let retValue = {}

  defaultMinValue = target === 'store' ? null : defaultMinValue

  if (value.min !== null || value.max !== null) {
    retValue.min = value.min === null ? defaultMinValue : value.min
  }

  if (value.max !== null) {
    retValue.max = value.max
  }

  if (Object.keys(retValue).length) {
    return {
      [key]: retValue
    }
  } else {
    return retValue
  }
}

const getGraphQLParamForIntRangeByQuery = (
  key,
  value,
  target,
  defaultMinValue = 0
) => {
  const retValue = {}

  defaultMinValue = target === 'store' ? null : defaultMinValue

  const data = value
    .split(',')
    .map(s => (isNaN(parseInt(s)) ? null : parseInt(s)))

  const min = data[0]
  const max = data[1]

  if (min !== null || max !== null) {
    retValue.min = min === null ? defaultMinValue : min
  }

  // note: remove condition because vue lose reactivity for non existing values in params
  // not sure at the moment if there are side effects because of this change
  // if (max !== null) {
  retValue.max = max
  // }

  if (Object.keys(retValue).length) {
    return {
      [key]: retValue
    }
  } else {
    return retValue
  }
}

const getGraphQLParamForIntRange = (
  key,
  value,
  source,
  target,
  minValue = 0
) => {
  if (source === 'query') {
    return getGraphQLParamForIntRangeByQuery(key, value, target, minValue)
  } else if (source === 'store') {
    return getGraphQLParamForIntRangeByStore(key, value, target, minValue)
  } else {
    return false
  }
}

const getGraphQLParamForDateRangeByStore = (key, value) => {
  let retValue = {}

  const toISODate = date =>
    date.getFullYear() +
    '-' +
    String(date.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(date.getDate()).padStart(2, '0')

  if (value !== null) {
    const today = new Date()
    let minDate = new Date()
    let maxDate = today

    switch (value) {
      case 'ONE_DAY':
        minDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 1
        )
        break
      case 'THREE_DAYS':
        minDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 3
        )
        break
      case 'FIVE_DAYS':
        minDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 5
        )
        break
      case 'MORE_THAN_FIVE_DAYS':
        maxDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 5
        )
        minDate = new Date(
          today.getFullYear() - 10,
          today.getMonth(),
          today.getDate() - 5
        )
        break
    }
    retValue.min = toISODate(minDate)
    retValue.max = toISODate(maxDate)
  }

  if (Object.keys(retValue).length) {
    return {
      [key]: retValue
    }
  } else {
    return retValue
  }
}

const getGraphQLParamForDateRangeByQuery = (key, value, target) => {
  if (target === 'store') {
    return {
      [key]: value
    }
  }
  return value
}

const getGraphQLParamForDateRange = (key, value, source, target) => {
  if (source === 'query') {
    return getGraphQLParamForDateRangeByQuery(key, value, target)
  } else if (source === 'store') {
    return getGraphQLParamForDateRangeByStore(key, value, target)
  } else {
    return false
  }
}

const getParamForStringRange = (key, value) => {
  return getParamForIntRange(key, value)
}

const getGraphQLParamForStringRangeByStore = (
  key,
  value,
  target,
  defaultMinValue = 0
) => {
  const retValue = getGraphQLParamForIntRangeByStore(
    key,
    value,
    target,
    defaultMinValue
  )

  if (retValue[key] !== undefined) {
    if (retValue[key].min !== undefined) {
      retValue[key].min = String(retValue[key].min)
    }

    if (retValue[key].max !== undefined) {
      retValue[key].max = String(retValue[key].max)
    }
  }

  return retValue
}

const getGraphQLParamForStringRangeByQuery = (
  key,
  value,
  target,
  defaultMinValue = 0
) => {
  const retValue = getGraphQLParamForIntRangeByQuery(
    key,
    value,
    target,
    defaultMinValue
  )

  if (retValue[key] !== undefined) {
    if (retValue[key].min !== undefined) {
      retValue[key].min = String(retValue[key].min)
    }

    if (retValue[key].max !== undefined) {
      retValue[key].max = String(retValue[key].max)
    }
  }

  return retValue
}

const getGraphQLParamForStringRange = (key, value, source, minValue = 0) => {
  if (source === 'query') {
    return getGraphQLParamForStringRangeByQuery(key, value, minValue)
  } else if (source === 'store') {
    return getGraphQLParamForStringRangeByStore(key, value, minValue)
  } else {
    return false
  }
}

const getParamForFlags = (key, value) => {
  const retObj = {
    key
  }

  if (value !== undefined) {
    retObj.value = value.map(v => v.name)
  }

  return retObj
}

const getGraphQLParamForFlags = (value, source) => {
  if (source === 'store') {
    return {
      flags: value
    }
  } else {
    const receivedValues = Array.isArray(value) ? value : [value]

    const retValue = { flags: [] }
    receivedValues.forEach(val => {
      retValue.flags.push({
        name: val,
        value: true
      })
    })

    return retValue
  }
}

const getGraphQLParamForArrayOfStrings = (key, value) => {
  const retValue = {}
  const receivedValues = [value]

  if (value.length === 0) {
    return null
  }
  if (receivedValues.length > 0 && receivedValues[0]) {
    retValue[key] = receivedValues
      .filter((value, index, self) => self.indexOf(value) === index)
      .flat()
  }
  return retValue
}

const getParamsForFromValues = (key, value) => {
  const retObj = { key }

  if (value !== undefined) {
    retObj.value = value.length !== 0 ? value.join(',') : null
  }
  return retObj
}

const getGraphQLParamsForFromValues = (key, value, source) => {
  if (source === 'store') {
    value = value.join(',')
  }

  const retValue = {}
  const receivedValues = value.length > 0 ? String(value).split(',') : []

  if (value.length === 0) {
    return null
  }
  if (receivedValues.length > 0 && receivedValues[0].length > 0) {
    retValue[key] = receivedValues.filter(
      (value, index, self) => self.indexOf(value) === index
    )
  }
  return retValue
}

export const searchParamsMapping = [
  {
    local: 'make',
    param: 'make',
    graphQL: value => getGraphQLParamForArrayOfStrings('make', value)
  },
  {
    local: 'modelGroup',
    param: 'modelGroup',
    graphQL: value => getGraphQLParamForArrayOfStrings('modelGroup', value)
  },
  {
    local: 'model',
    param: 'model',
    graphQL: value => getGraphQLParamForArrayOfStrings('model', value)
  },
  {
    local: 'category',
    param: 'category',
    graphQL: value => getGraphQLParamForArrayOfStrings('category', value)
  },
  {
    local: 'condition',
    param: 'condition',
    graphQL: value => getGraphQLParamForArrayOfStrings('condition', value)
  },
  {
    local: 'usageType',
    param: 'usageType',
    graphQL: value => getGraphQLParamForArrayOfStrings('usageType', value)
  },
  {
    local: 'amUsageType',
    param: 'amUsageType',
    graphQL: value => getGraphQLParamForArrayOfStrings('amUsageType', value)
  },
  {
    local: 'fuel',
    param: 'fuel',
    graphQL: value => getGraphQLParamForArrayOfStrings('fuel', value)
  },
  {
    local: 'firstRegistration',
    param: value => getParamForIntRange('firstRegistration', value),
    graphQL: (value, source, target) =>
      getGraphQLParamForIntRange('firstRegistration', value, source, target)
  },
  {
    local: 'power',
    param: value => getParamForIntRange('power', value),
    graphQL: (value, source, target) =>
      getGraphQLParamForIntRange('power', value, source, target)
  },
  {
    local: 'mileage',
    param: value => getParamForIntRange('mileage', value),
    graphQL: (value, source, target) =>
      getGraphQLParamForIntRange('mileage', value, source, target)
  },
  {
    local: 'price', // TODO: obsolet, can be done when no more used
    param: value => getParamForStringRange('price', value),
    graphQL: (value, source) =>
      getGraphQLParamForStringRange('price', value, source)
  },
  {
    local: 'consumerPriceGrossRange',
    param: value => getParamForIntRange('consumerPriceGrossRange', value),
    graphQL: (value, source, target) =>
      getGraphQLParamForIntRange(
        'consumerPriceGrossRange',
        value,
        source,
        target
      )
  },
  {
    local: 'financingMonthlyInstallmentRange',
    param: value =>
      getParamForIntRange('financingMonthlyInstallmentRange', value),
    graphQL: (value, source, target) =>
      getGraphQLParamForIntRange(
        'financingMonthlyInstallmentRange',
        value,
        source,
        target
      )
  },
  {
    local: 'leasingGrossRateRange',
    param: value => getParamForIntRange('leasingGrossRateRange', value),
    graphQL: (value, source, target) =>
      getGraphQLParamForIntRange('leasingGrossRateRange', value, source, target)
  },
  {
    local: 'gearbox',
    param: 'gearbox',
    graphQL: value => getGraphQLParamForArrayOfStrings('gearbox', value)
  },
  {
    local: 'drivingMode',
    param: 'drivingMode',
    graphQL: value => getGraphQLParamForArrayOfStrings('drivingMode', value)
  },
  {
    local: 'cubicCapacity',
    param: value => getParamForIntRange('cubicCapacity', value),
    graphQL: (value, source, target) =>
      getGraphQLParamForIntRange('cubicCapacity', value, source, target)
  },
  {
    local: 'emissionSticker',
    param: value => getParamsForFromValues('emissionSticker', value),
    graphQL: (value, source) =>
      getGraphQLParamsForFromValues('emissionSticker', value, source)
  },
  {
    local: 'exteriorColor',
    param: 'exteriorColor',
    graphQL: value => getGraphQLParamForArrayOfStrings('exteriorColor', value)
  },
  {
    local: 'interiorColor',
    param: 'interiorColor',
    graphQL: value => getGraphQLParamForArrayOfStrings('interiorColor', value)
  },
  {
    local: 'trailerCouplingType',
    param: 'trailerCouplingType',
    graphQL: 'trailerCouplingType'
  },
  {
    local: 'parkingAssistants',
    param: 'parkingAssistants',
    graphQL: value =>
      getGraphQLParamForArrayOfStrings('parkingAssistants', value)
  },
  {
    local: 'speedControl',
    param: 'speedControl',
    graphQL: 'speedControl'
  },
  {
    local: 'flags',
    param: value => getParamForFlags('flags', value),
    graphQL: (value, source) => getGraphQLParamForFlags(value, source)
  },
  {
    local: 'interiorType',
    param: 'interiorType',
    graphQL: value => getGraphQLParamForArrayOfStrings('interiorType', value)
  },
  {
    local: 'airbag',
    param: 'airbag',
    graphQL: 'airbag'
  },
  {
    local: 'climatisation',
    param: 'climatisation',
    graphQL: value => getGraphQLParamForArrayOfStrings('climatisation', value)
  },
  {
    local: 'consumptionCombined',
    param: value => getParamForFloat('consumptionCombined', value),
    graphQL: value => getGraphQLParamForFloat('consumptionCombined', value)
  },
  {
    local: 'emissionClass',
    param: value => getParamsForFromValues('emissionClass', value),
    graphQL: (value, source) =>
      getGraphQLParamsForFromValues('emissionClass', value, source)
  },
  {
    local: 'bendingLightsType',
    param: 'bendingLightsType',
    graphQL: value =>
      getGraphQLParamForArrayOfStrings('bendingLightsType', value)
  },
  {
    local: 'headlightType',
    param: 'headlightType',
    graphQL: value => getGraphQLParamForArrayOfStrings('headlightType', value)
  },
  {
    local: 'daytimeRunningLamps',
    param: 'daytimeRunningLamps',
    graphQL: value =>
      getGraphQLParamForArrayOfStrings('daytimeRunningLamps', value)
  },
  {
    local: 'breakdownService',
    param: 'breakdownService',
    graphQL: value =>
      getGraphQLParamForArrayOfStrings('breakdownService', value)
  },
  {
    local: 'radio',
    param: 'radio',
    graphQL: value => getGraphQLParamForArrayOfStrings('radio', value)
  },
  {
    local: 'vatDeductible',
    param: value => getParamForBoolean('vatDeductible', value, true),
    graphQL: value => getGraphQLParamForBoolean('vatDeductible', value, true)
  },
  {
    local: 'isCommercialOffer',
    param: value => getParamForBoolean('isCommercialOffer', value),
    graphQL: value => getGraphQLParamForBoolean('isCommercialOffer', value)
  },
  {
    local: 'usedCarSeal',
    param: 'usedCarSeal',
    graphQL: value => getGraphQLParamForArrayOfStrings('usedCarSeal', value)
  },
  {
    local: 'onlineRange',
    param: 'onlineRange',
    graphQL: (value, source, target) =>
      getGraphQLParamForDateRange('onlineRange', value, source, target)
  },
  {
    local: 'vehicleClass',
    param: 'vehicleClass',
    graphQL: value => getGraphQLParamForArrayOfStrings('vehicleClass', value)
  }

  // TODO: add all others
]

// TODO move this out to some kind of util function lib
export const getUrlQueryByLocale = datapoint => {
  const query = {}

  searchParamsMapping.forEach(p => {
    if (datapoint[p.local] !== undefined) {
      const storeValue = datapoint[p.local]

      if (typeof p.param === 'function') {
        const processedParam = p.param(storeValue)
        if (
          processedParam.value !== null &&
          (!Array.isArray(processedParam.value) || processedParam.value.length)
        ) {
          Object.assign(query, {
            [processedParam.key]: processedParam.value
          })
        }
      } else if (Array.isArray(storeValue)) {
        if (storeValue.length > 0) {
          query[p.param] = storeValue
        }
      } else if (storeValue !== null && storeValue !== '') {
        query[p.param] = storeValue
      }
    }
  })

  return query
}

export default {
  computed: {
    ...mapGetters('searchHybrid', ['getInitialSearchParams'])
  },
  methods: {
    // used by home/quick-search
    // TODO: check if this method and getGraphQLParamsByStore can be the same?
    // TOD: check if locale attribute in mapping is really necessary
    getUrlQueryByLocale(paramsReference = 'params') {
      const query = {}

      let datapoint

      if (typeof paramsReference === 'string') {
        datapoint = this.$data[paramsReference]
      } else {
        datapoint = paramsReference
      }

      searchParamsMapping.forEach(p => {
        if (datapoint[p.local] !== undefined) {
          const storeValue = datapoint[p.local]

          if (typeof p.param === 'function') {
            const processedParam = p.param(storeValue)
            if (
              processedParam.value !== null &&
              (!Array.isArray(processedParam.value) ||
                processedParam.value.length)
            ) {
              Object.assign(query, {
                [processedParam.key]: processedParam.value
              })
            }
          } else if (Array.isArray(storeValue)) {
            if (storeValue.length > 0) {
              query[p.param] = storeValue
            }
          } else if (storeValue !== null && storeValue !== '') {
            query[p.param] = storeValue
          }
        }
      })
      return query
    },

    getUrlQueryByStore() {
      const query = {}

      searchParamsMapping.forEach(p => {
        if (this.$data.params[p.local] !== undefined) {
          const storeValue = this.$data.params[p.local]
          if (typeof p.param === 'function') {
            const processedParam = p.param(storeValue)
            if (processedParam.value !== null) {
              Object.assign(query, {
                [processedParam.key]: processedParam.value
              })
            }
          } else if (Array.isArray(storeValue)) {
            if (storeValue.length > 0) {
              query[p.param] = storeValue
            }
          } else if (storeValue !== null && storeValue !== '') {
            query[p.param] = storeValue
          }
        }
      })
      return query
    },

    getGraphQLParamsByStore() {
      let query = {
        flags: []
      }

      searchParamsMapping.forEach(p => {
        if (this.$data.params[p.local] !== undefined) {
          const storeValue = this.$data.params[p.local]
          if (typeof p.graphQL === 'function') {
            query = Object.assign({}, query, p.graphQL(storeValue, 'store'))
          } else if (Array.isArray(storeValue)) {
            if (storeValue.length > 0) {
              query[p.graphQL] = storeValue
            }
          } else if (storeValue !== null && storeValue !== '') {
            // TODO: why compare with ''?
            query[p.graphQL] = storeValue
          }
        }
      })
      return query
    },

    // used by home/quick-search
    // TODO: check if this method and getGraphQLParamsByStore can be the same?
    // TOD: check if locale attribute in mapping is really necessary
    getGraphQLParamsByLocale(paramsReference = 'params') {
      let query = {
        flags: []
      }

      let datapoint

      if (typeof paramsReference === 'string') {
        datapoint = this.$data[paramsReference]
      } else {
        datapoint = paramsReference
      }

      searchParamsMapping.forEach(p => {
        if (datapoint[p.local] !== undefined) {
          const storeValue = datapoint[p.local]
          if (typeof p.graphQL === 'function') {
            query = Object.assign({}, query, p.graphQL(storeValue, 'store'))
          } else if (Array.isArray(storeValue)) {
            if (storeValue.length > 0) {
              query[p.graphQL] = storeValue
            }
          } else if (storeValue !== null && storeValue !== '') {
            // TODO: why compare with ''?
            query[p.graphQL] = storeValue
          }
        }
      })
      // query.flags = [] // have comment this out, seems to be wrong to me as flags are ignored

      return query
    },

    getGraphQLParamsByQuery(target = 'graphQL') {
      const urlQuery = this.$route.query

      const graphQLParams = {
        flags: []
      }

      Object.keys(urlQuery).forEach(key => {
        const foundMapping = searchParamsMapping.find(m => {
          if (typeof m.param === 'function') {
            return m.param().key === key
          } else {
            return m.param === key
          }
        })

        if (foundMapping) {
          if (typeof foundMapping.graphQL === 'function') {
            Object.assign(
              graphQLParams,
              foundMapping.graphQL(urlQuery[key], 'query', target)
            )
          } else {
            graphQLParams[foundMapping.graphQL] = urlQuery[key]
          }
        }
      })
      return graphQLParams
    },

    getStoreParamsByQuery() {
      return Object.assign(
        {},
        this.getInitialSearchParams(),
        this.getGraphQLParamsByQuery('store')
      )
    }
  }
}
